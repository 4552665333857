<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
   
    
    <v-card class="card">
      <v-card-title class="heading justify-center">OPAC Hits Reports</v-card-title>
      <v-card-text> <br/>
            <v-row>
          
            <v-col cols="12" sm="3">
                <label class="pl-5">From Date</label>

                <br />
                <!-- {{formdata.resedential_premit_issue_date}} -->
                <input
                  type="date"
                  placeholder="From Date"
                  class="example"
                  v-model="selectedfromdate"
                
                />
              </v-col>
              <v-col cols="12" sm="3">
                <label class="pl-5"> To Date</label>

                <br />
                <!-- {{formdata.resedential_premit_issue_date}} -->
                <input
                  type="date"
                  placeholder="To Date"
                  class="example"
                  v-model="selectedtodate"
                
                />
              </v-col>
               <v-col cols="12" sm="3">
               
<br/>
                <br />
                <!-- {{formdata.resedential_premit_issue_date}} -->
               <v-btn class="primary" @click="gethistory">Search<v-icon>mdi-search</v-icon></v-btn>
              </v-col>
            </v-row>
            
      </v-card-text>
    </v-card>
    
     <div class="pa-5 ma-5 grey lighten-3" v-if="Array.isArray(bookhistory) && bookhistory.length">
      <v-row justify="end">
      <v-btn   @click="exportexcel()" color="success" dark class="mb-2">
          <v-icon dark>mdi-file-excel</v-icon>
        </v-btn>
         </v-row>
        <h3>OPAC Hits Reports</h3><br/>
     
         <v-simple-table class=" grey lighten-3"  id="exceltable" style="display:none;">
        <tbody>
          <tr>
          <td align="center">Organization</td>
          <td align="center">Date</td>
          <td align="center">No Of Hits</td>
        
        </tr>
          <tr v-for="(item,index) in bookhistory" :key='index'>
              <td>{{item.org}}</td>
                 <td>{{item.date.split("T")[0].split("-")[2]+'-'+item.date.split("T")[0].split("-")[1]+"-"+item.date.split("T")[0].split("-")[0]}}</td>
             <td>{{item.noofhits}}</td>
          </tr>
        </tbody>
        </v-simple-table>
          <v-simple-table class=" grey lighten-3" >
      
        <thead>
         <td align="center"><strong>Organization</strong></td>
          <td align="center"><strong>Date</strong></td>
          <td align="center"><strong>No Of Hits</strong></td>
        </thead>
        <tbody>
          <tr v-for="(item,index) in bookhistory" :key='index'>
              
               <td align="center">{{item.org}}</td>
                 <td align="center">{{item.date.split("T")[0].split("-")[2]+'-'+item.date.split("T")[0].split("-")[1]+"-"+item.date.split("T")[0].split("-")[0]}}</td>
             <td align="center">{{item.noofhits}}</td>
          </tr>
        </tbody>
        </v-simple-table>
        </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import {table2excel} from "../jquery.table2excel";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
  data: () => ({

      itemlist:[],
      bookhistory:[],
      seletedbook:"",
      selectedfromdate:null,
      selectedtodate:null,
      snackbar: false,
            snackbar_msg: "",
            color:""
  }),

  mounted() {
     
    // axios
    //                 .post("/Librarian/getBookassectionnumber")
    //                 .then((res) => {
    //                  this.itemlist= res.data.itemlist;
                     
    //                 })
                    this.selectedtodate = new Date().toJSON().slice(0,10);
                     //this.selectedfromdate = new Date().setMonth(this.selectedtodate.getMonth() - 1);
                     var d = new Date();
                      d.setMonth(d.getMonth() - 1);
                      this.selectedfromdate = d.toJSON().slice(0,10);
                    console.log("selectedfromdate");
                     console.log(this.selectedfromdate);
  },

  methods: {
     showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
      exportexcel() {
     
        $("#exceltable").table2excel({
    
    name: "Worksheet Name",
    filename: "Opac_Hits_report", //do not include extension
    fileext: ".xls" // file extension
  }); 
   
    },
      gethistory(){
          var params={fromdate:this.selectedfromdate,todate:this.selectedtodate};
             axios
                    .post("/Librarian/opachitsreport",params)
                    .then((res) => {
                     this.bookhistory= res.data.bookhistory;
                     
                    })
                   
      }
  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
.v-icon-size {
  font-size: 20px;
}
.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}
.edit-avatar:hover {
  background-color: #b0bec5;
}
.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}
.edit-v-icon:hover {
  color: white;
}
.fields {
  padding: 0px !important;
}
.isdonated {
  margin-top: 15px;
  padding-left: 15px !important;
}
.check {
  color: green;
  font-size: 20px;
  padding-right: 10px;
}
.cross {
  color: red;
  font-size: 20px;
  padding-right: 5px;
  padding-left: 5px;
}

.example {
  margin-top: 0px;
  background: #ffff;
  border: 3px solid rgb(171, 169, 169);
  border-radius: 10px;
  height: 55px;
  width: 100%; 
}

.example:focus {
 
   border-radius: 10px;
   border-color: #2f74b0;
     border-width: 2px;
       
       outline: 0;
}

.required:after {
  content: " *";
  color: red;
}

.date {
  border: 2px solid red;
}

</style>